import React, { useState, useRef } from "react";
import "./ModalAddPrivacyPolicy.css";
import { Button, Modal, Input, InputNumber, Checkbox, Image } from "antd";
import { addIcon, editIcon, termsAndUse } from "../../assets";
import { useSelector } from "react-redux";
import { Editor } from "@tinymce/tinymce-react";
import { callApi } from "../../api/apiCaller";
import routes from "../../api/routes";
import { upload } from "../../helper/helper";
// import { useRef } from "react";

const ModalAddPrivacyPolicy = ({
  setShowModal,
  showModal,
  item,
  setIsLoading,
  addProduct,
  setAddProduct,
}) => {
  console.log("ccccccccc", item?.data);

  const getDescriptionText = (htmlString) => {
    const tempElement = document.createElement("div");
    tempElement.innerHTML = htmlString;

    // console.log("content",tempElement.textContent)
    // console.log("innerText",tempElement.innerText)

    return tempElement.textContent || tempElement.innerText || "";
  };

  const [description, setDescription] = useState(
    addProduct ? "" : getDescriptionText(item?.data)
  );
  // const [description, setDescription] = useState("")
  const fileInputRef = useRef(null);
  const editorRef = useRef(null);

  console.log("description is", description);

  const createProduct = (e) => {
    e.preventDefault();
    e.stopPropagation();

    console.log(2222222222);
    let getRes = (res) => {
      console.log("res of create service", res);
      setShowModal(false);
      setAddProduct(false);
    };

    let body = {
      // heading: heading,
      description: editorRef.current.getContent(),
    };
    callApi(
      "POST",
      routes.createPrivacyPolicy,
      body,
      setIsLoading,
      getRes,
      (error) => {
        console.log("error", error);
      }
    );
  };

  const updateProduct = (e) => {
    e.preventDefault();
    e.stopPropagation();
    let getRes = (res) => {
      console.log(11111111);
      // console.log("res of update product", res);
      setShowModal(false);
    };
    let body = {
      // heading: heading,
      data: editorRef.current.getContent(),
    };
    console.log("body", body);

    callApi(
      "PATCH",
      `${routes.updatePrivacyPolicy}/${item?._id}`,
      body,
      setIsLoading,
      getRes,
      (error) => {
        console.log("error", error);
      }
    );
    console.log("url", `${routes.updatePrivacyPolicy}/${item?._id}`);
  };

  const TinyEditor = ({ onChange, editorRef, value, initialValue }) => {
    // const TinyEditor = ({editorRef, value, initialValue}) => {
    const log = () => {
      // if (editorRef.current) {
      // console.log('rrrrrrrrrrr');
      console.log("loggging editor", editorRef.current.getContent());
      // }
    };
    return (
      <>
        <Editor
          // onChange={onChange}
          apiKey="cf14wdmd1za1etvvxuvjbs0s21ywqrz89miuwzjcmw2lmm84"
          initialValue={description}
          onInit={(evt, editor, e) => {
            editorRef.current = editor;
            editorRef.current.setContent(item?.data);
          }}
          // initialValue={initialValue}
          init={{
            plugins: "image link",
            file_picker_types: "file image media",
            relative_urls: false,
            remove_script_host: false,
          }}
        />
        <button onClick={log}></button>
      </>
    );
  };
  return (
    <div className="add-product-modal-main-container">
      <div className="add-product-modal-container">
        <div style={{ marginTop: "5rem" }}></div>
        <div>
          <h3>Content</h3>
          <TinyEditor
            editorRef={editorRef}
            initialValue={description}
            value={description}
            onChange={(content, e) => {
              console.log("content", content);
              console.log("eeeeeeeee", e);
              setDescription(content);
            }}
            style={{ width: "80vw" }}
          />
        </div>

        <div className="modal-btn-container"></div>
        <div style={{ marginBottom: "3rem" }}>
          <Button
            onClick={() => {
              setShowModal(false);
            }}
            type="default"
            danger
          >
            Cancel
          </Button>
          {addProduct ? (
            <Button
              onClick={createProduct}
              style={{ marginLeft: "2rem" }}
              type="primary"
              // disabled={isFormIncomplete()}
            >
              Add
            </Button>
          ) : (
            <Button
              onClick={updateProduct}
              style={{ marginLeft: "2rem" }}
              type="primary"
            >
              Update
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};

export default ModalAddPrivacyPolicy;
