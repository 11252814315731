import React, { useState, useEffect } from "react";
import "./audios.css";
import { Breadcrumb, Button, Modal, Image, Table, Input } from "antd";
import routes from "../../api/routes";
import { callApi } from "../../api/apiCaller";
import Loader from "../../components/loader/loader";
import { useDispatch } from "react-redux";
import { homeIcon } from "../../assets";
import { GreenNotify, RedNotify } from "../../helper/helper";
import moment from "moment/moment";

const Audios = () => {
  const dispatch = useDispatch();
  const [isloading, setIsLoading] = useState(false);
  const [products, setProducts] = useState([]);
  const [selectedAudio, setSelectedAudio] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [showRejectModal, setShowRejectModal] = useState(false);
  const [rejectReason, setRejectReason] = useState("");

  const getPreferences = () => {
    let getRes = (res) => {
      console.log(res?.data?.audios);
      setProducts(res?.data?.audios);
    };

    callApi("GET", routes.getAudios, null, setIsLoading, getRes, (error) => {
      console.log("error", error);
    });
  };

  const handleApprove = (id) => {
    setProducts((prevProducts) =>
      prevProducts.map((audio) =>
        audio._id === id ? { ...audio, status: "approved" } : audio
      )
    );

    let getRes = (res) => {
      if (res.status === 400) {
        RedNotify(res.message);
        return;
      } else if (res.status === 200) {
        GreenNotify("Audio Accept Successfully.");
        return;
      }
      getPreferences();
    };

    let body = { status: "approved" };
    callApi(
      "PATCH",
      `${routes.updateAudio}/${id}`,
      body,
      setIsLoading,
      getRes,
      (error) => {
        console.log("error", error);
      }
    );
    setShowModal(false);
  };

  const handleReject = (id) => {
    setSelectedAudio(id);
    setShowRejectModal(true);
  };

  const handleRejectConfirm = () => {
    setProducts((prevProducts) =>
      prevProducts.map((audio) =>
        audio._id === selectedAudio ? { ...audio, status: "rejected" } : audio
      )
    );

    let getRes = (res) => {
      if (res.status === 400) {
        RedNotify(res.message);
        return;
      } else if (res.status === 200) {
        GreenNotify("Audio Reject Successfully.");
        return;
      }
      getPreferences();
    };

    let body = { status: "rejected", rejectionReason: rejectReason };
    callApi(
      "PATCH",
      `${routes.updateAudio}/${selectedAudio}`,
      body,
      setIsLoading,
      getRes,
      (error) => {
        console.log("error", error);
      }
    );
    setShowRejectModal(false);
    setRejectReason("");
  };

  const columns = [
    {
      title: "Title",
      dataIndex: "title",
      align: "center",
      className: "role-name-column-header",
      width: 300,
    },
    {
      title: "Image",
      dataIndex: "image",
      align: "center",
      className: "action-column-header",
      width: 200,
    },
    {
      title: "Category",
      dataIndex: "category",
      align: "center",
      className: "action-column-header",
      width: 200,
      filters: [
        { text: "Affirmations", value: "Affirmations" },
        { text: "Guided Meditations", value: "Guided Meditations" },
        { text: "Music", value: "Music" },
        { text: "Challenges", value: "Challenges" },
        { text: "Journaling", value: "Journaling" },
        { text: "Yoga", value: "Yoga" },
        { text: "Fitness", value: "Fitness" },
        { text: "Coaches", value: "Coaches" },
      ],
      onFilter: (value, record) => record.category.indexOf(value) === 0,
    },
    {
      title: "Coach Detail",
      dataIndex: "coachDetail",
      align: "center",
      className: "action-column-header",
      width: 300,
    },
    {
      title: "Status",
      dataIndex: "status",
      align: "center",
      className: "type-name-column-header",
      width: 300,
      filters: [
        { text: "Pending", value: "pending" },
        { text: "Approved", value: "approved" },
        { text: "Rejected", value: "rejected" },
      ],
      onFilter: (value, record) => record.status.indexOf(value) === 0,
      render: (status, record) => {
        return status === "pending" ? (
          <div>
            <Button
              type="primary"
              onClick={() => handleApprove(record._id)}
              style={{ marginRight: "10px" }}
            >
              Approve
            </Button>
            <Button danger onClick={() => handleReject(record._id)}>
              Reject
            </Button>
          </div>
        ) : (
          <span>{status}</span>
        );
      },
    },
    {
      title: "Created At",
      dataIndex: "created",
      align: "center",
      width: 200,
    },
    {
      title: "Action",
      dataIndex: "action",
      align: "center",
      width: 200,
      render: (_, record) => (
        <Button
          type="primary"
          onClick={() => {
            setSelectedAudio(record);
            setShowModal(true);
          }}
        >
          View Files
        </Button>
      ),
    },
  ];

  useEffect(() => {
    getPreferences();
  }, []);

  const data = products
    ?.sort((a, b) => {
      const statusOrder = { pending: 1, approved: 2, rejected: 3 };

      return statusOrder[a.status] - statusOrder[b.status];
    })
    ?.map((item, index) => {
      let bodyStyle = {
        fontWeight: "bold",
        fontSize: "18px",
        marginRight: "1rem",
      };

      return {
        key: index,
        title: item?.title,
        created: moment(item?.createdAt)
          .local()
          .format("MMMM Do YYYY, h:mm:ss A"),
        status: item?.status,
        _id: item?._id,
        files: item?.files,
        category: item?.category,
        image: (
          <div className="product-list-image">
            <Image width={50} src={item?.image} alt="profile-image" />
          </div>
        ),
        coachDetail: (
          <div style={{ textAlign: "left" }}>
            <p>
              {" "}
              <span style={bodyStyle}>Name: </span>
              {item?.user?.name}
            </p>
            <p>
              <span style={bodyStyle}>Email:</span>
              {item?.user?.email}
            </p>
          </div>
        ),
      };
    })
    .filter((item) => {
      const usernameMatch = item.title
        ?.toLowerCase()
        .includes(searchText?.toLowerCase());
      return usernameMatch;
    });

  return (
    <div className="admin-products-main-container">
      <Loader loading={isloading} />
      <Breadcrumb separator=">" className="bread-crumb">
        <div className="configure-server-home-icon">
          <img src={homeIcon} alt="home-icon" />
        </div>
        <Breadcrumb.Item>Home</Breadcrumb.Item>
        <Breadcrumb.Item>Audios</Breadcrumb.Item>
      </Breadcrumb>
      <div className="configure-server-roles-main-heading-container">
        <h1>Audios</h1>
        <div></div>
        <div className="search-inputs" style={{ width: "30rem" }}>
          <Input
            placeholder="Search Title"
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
          />
        </div>
      </div>
      <div className="server-roles-tb-main-container">
        <Table
          columns={columns}
          dataSource={data}
          pagination={data?.length > 10}
          className="subscriptionapi-table"
          scroll={{ x: "max-content" }}
        />

        {/* Modal for showing files only */}
        <Modal
          title="Files"
          visible={showModal}
          onCancel={() => setShowModal(false)}
          footer={null}
        >
          {selectedAudio && (
            <div
              style={{ display: "flex", flexDirection: "column", gap: "15px" }}
            >
              {selectedAudio.files?.map((file, index) => (
                <div
                  key={file?._id?.$oid}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    padding: "10px",
                    border: "1px solid #e0e0e0",
                    borderRadius: "8px",
                    backgroundColor: "#f9f9f9",
                  }}
                >
                  {file.image && (
                    <div style={{ marginRight: "15px", textAlign: "center" }}>
                      <Image
                        width={80}
                        height={80}
                        src={file.image}
                        alt={`file-image-${index + 1}`}
                        style={{ borderRadius: "50%" }}
                      />
                      <p>thumbnail {index + 1}</p>
                    </div>
                  )}
                  {file.file && (
                    <div style={{ flexGrow: 1 }}>
                      <audio controls style={{ width: "100%" }}>
                        <source src={file.file} type="audio/mpeg" />
                        Your browser does not support the audio element.
                      </audio>
                      <p>audio {index + 1}</p>
                    </div>
                  )}
                </div>
              ))}
            </div>
          )}

          {/* Effect to pause and reset audio when modal is closed */}
          {useEffect(() => {
            if (!showModal) {
              const audios = document.querySelectorAll("audio");
              audios.forEach((audio) => {
                audio.pause();
                audio.currentTime = 0;
              });
            }
          }, [showModal])}
        </Modal>

        {/* Modal for reject reason */}
        <Modal
          title="Reject Reason"
          visible={showRejectModal}
          onCancel={() => setShowRejectModal(false)}
          footer={[
            <Button key="back" onClick={() => setShowRejectModal(false)}>
              Cancel
            </Button>,
            <Button
              key="submit"
              type="primary"
              onClick={handleRejectConfirm}
              disabled={!rejectReason.trim()}
            >
              Send
            </Button>,
          ]}
        >
          <Input.TextArea
            placeholder="Enter reject reason here..."
            value={rejectReason}
            onChange={(e) => setRejectReason(e.target.value)}
            rows={4}
          />
        </Modal>
      </div>
    </div>
  );
};

export default Audios;
