import React, { useState, useRef, useEffect } from "react";
import "./modalAddCategory.css";
import { Button, Modal, Input, Select, InputNumber } from "antd";
import { callApi } from "../../api/apiCaller";
import { GreenNotify, RedNotify } from "../../helper/helper";
import routes from "../../api/routes";
import { upload, uploadData } from "../../helper/helper";


const ModalAddCategory = ({
  setShowModal,
  showModal,
  item,
  setIsLoading,
  addProduct,
  setAddProduct,
}) => {
  const [title, setTitle] = useState(addProduct ? "" : item?.title);
  const [profilePic, setProfilePic] = useState(
    addProduct ? "" : item?.profilePic
  );
  const fileInputRef = useRef(null);
  const [titleError, setTitleError] = useState("");

  // useEffect(() => {
  //   if (title.length > 30) {
  //     setTitleError("Title must be 30 characters or less");
  //   } else {
  //     setTitleError("");
  //   }
  // }, [title]);

  const pickImageFile = () => {
    fileInputRef.current.click();
  };

  const dummyImage =
    "https://novathreadbucket.s3.amazonaws.com/nova-app-1685176470232-dummy.PNG";

  const createProduct = async () => {
    try {
      let getRes = (res) => {
        if (res.success == false) {
          RedNotify("An unexpected error occurred.");
          return;
        }
        if (res.status == 200) {
          GreenNotify("Category created successfully.");
        }
        setShowModal(false);
        setAddProduct(false);
      };

      const body = {
        profilePic,
        title,
      };
      await callApi(
        "POST",
        routes.createCategory,
        body,
        setIsLoading,
        getRes,
        (res) => {
          if (res.success === false) {
            RedNotify("An unexpected error occurred.");
            return;
          }
          setShowModal(false);
          setAddProduct(false);
        },
        (error) => {
          RedNotify(error);
          console.log("Error:", error);
        }
      );
    } catch (error) {
      setIsLoading(false);
      console.error("Create product error:", error);
    }
  };

  const updateProduct = async () => {
    let getRes = (res) => {
      if (res.status == 400) {
        RedNotify(res.message);
        return;
      }
      setShowModal(false);
    };

    let body = {
      profilePic: profilePic,
      title: title,
    };

    callApi(
      "PATCH",
      `${routes.updateCategory}/${item?._id}`,
      body,
      setIsLoading,
      getRes,
      (error) => {
        console.log("error", error);
      }
    );
    // }
  };

  const isFormIncomplete = () => {
    return !title || !profilePic;
  };

  return (
    <div className="add-product-modal-main-container-concert">
      <div className="add-product-modal-container-concert">
        <div></div>

        <div
          style={{ marginTop: "2rem" }}
          className="add-product-modal-input-title"
        >
          <h2>Title</h2>
          <Input
            value={title}
            placeholder="Title"
            onChange={(e) => {
              setTitle(e.target.value);
            }}
          />
        </div>

        <input
          type="file"
          ref={fileInputRef}
          style={{ display: "none" }} // Hide the file input
          onChange={upload((url) => setProfilePic(url), setIsLoading)}
        />
        <div
          onClick={pickImageFile}
          style={{ marginBottom: "2rem" }}
          className="add-product-modal-input-title"
        >
          <h2>Profile Picture</h2>
          <div className="add-product-modal-image">
            <img src={profilePic ? profilePic : dummyImage} alt="" />
          </div>
        </div>
        <div className="modal-btn-container"></div>
        <div style={{ marginBottom: "3rem" }}>
          <Button
            onClick={() => {
              setShowModal(false);
            }}
            type="default"
            danger
          >
            Cancel
          </Button>
          {addProduct ? (
            <Button
              onClick={createProduct}
              style={{ marginLeft: "2rem" }}
              type="primary"
              disabled={isFormIncomplete()}
            >
              Add Category
            </Button>
          ) : (
            <Button
              onClick={updateProduct}
              style={{ marginLeft: "2rem" }}
              type="primary"
            >
              Update
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};
export default ModalAddCategory;
