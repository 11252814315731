import React, { useState, useEffect } from "react";
import "./posts.css";
import { Breadcrumb, Image, Table, Input } from "antd";
import { DeleteOutlined } from "@ant-design/icons";
import routes from "../../api/routes";
import { callApi } from "../../api/apiCaller";
import Loader from "../../components/loader/loader";
import { useDispatch } from "react-redux";
import { homeIcon } from "../../assets";
import moment from "moment/moment";
import { GreenNotify, RedNotify } from "../../helper/helper";

const Posts = () => {
  const dispatch = useDispatch();
  const [isloading, setIsLoading] = useState(false);
  const [products, setProducts] = useState([]);
  const [searchText, setSearchText] = useState("");

  const getPreferences = () => {
    let getRes = (res) => {
      console.log(res?.data?.posts);
      setProducts(res?.data?.posts);
    };

    callApi("GET", routes.getPosts, null, setIsLoading, getRes, (error) => {
      console.log("error", error);
    });
  };

  const deletePost = (postId) => {
    const handleDeleteResponse = (res) => {
      GreenNotify("Post deleted successfully");
      setProducts(products.filter((post) => post._id !== postId));
    };

    callApi(
      "DELETE",
      `${routes.deletePost}/${postId}`,
      null,
      setIsLoading,
      handleDeleteResponse,
      (error) => {
        console.log("error", error);
        RedNotify("Failed to delete post");
      }
    );
  };

  const columns = [
    {
      title: "Caption",
      dataIndex: "caption",
      align: "center",
      className: "role-name-column-header",
      width: 600,
    },
    {
      title: "Image",
      dataIndex: "image",
      align: "center",
      className: "action-column-header",
      width: 150,
    },
    {
      title: "User Detail",
      dataIndex: "userDetail",
      align: "center",
      className: "action-column-header",
      width: 300,
    },
    {
      title: "Created At",
      dataIndex: "createdAt",
      align: "center",
      className: "action-column-header",
      width: 250,
    },
    {
      title: "Actions",
      dataIndex: "actions",
      align: "center",
      width: 150,
      render: (_, record) => (
        <DeleteOutlined
          style={{ color: "red", cursor: "pointer" }}
          onClick={() => deletePost(record.key)}
        />
      ),
    },
  ];

  useEffect(() => {
    getPreferences();
  }, []);

  const data = products
    ?.map((item) => {
      let bodyStyle = {
        fontWeight: "bold",
        fontSize: "18px",
        marginRight: "1rem",
      };

      return {
        key: item._id,
        caption: item?.caption,
        createdAt: moment(item?.createdAt)
          .local()
          .format("MMMM Do YYYY, h:mm:ss A"),
        image:
          item?.mediaType === "image" ? (
            <div className="product-list-image">
              <Image width={50} src={item?.url} alt="profile-image" />
            </div>
          ) : item?.mediaType === "video" ? (
            <video controls style={{ width: "100%", borderRadius: "10px" }}>
              <source src={item?.url} type="video/mp4" />
              Your browser does not support the video element.
            </video>
          ) : (
            "No media"
          ),
        userDetail: (
          <div style={{ textAlign: "left" }}>
            <p>
              <span style={bodyStyle}>Name: </span>
              {item?.user?.name}
            </p>
            <p>
              <span style={bodyStyle}>Email:</span>
              {item?.user?.email}
            </p>
          </div>
        ),
      };
    })
    .filter((item) => {
      const captionMatch = item.caption
        ?.toLowerCase()
        .includes(searchText?.toLowerCase());
      return captionMatch;
    });

  return (
    <div className="admin-products-main-container">
      <Loader loading={isloading} />
      <Breadcrumb separator=">" className="bread-crumb">
        <div className="configure-server-home-icon">
          <img src={homeIcon} alt="home-icon" />
        </div>
        <Breadcrumb.Item>Home</Breadcrumb.Item>
        <Breadcrumb.Item>Posts</Breadcrumb.Item>
      </Breadcrumb>
      <div className="configure-server-roles-main-heading-container">
        <h1>Posts</h1>
        <div></div>
        <div className="search-inputs" style={{ width: "30rem" }}>
          <Input
            placeholder="Search Caption"
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
          />
        </div>
      </div>
      <div className="server-roles-tb-main-container table-container">
        <Table
          columns={columns}
          dataSource={data}
          pagination={data?.length > 1}
          className="subscriptionapi-table"
          scroll={{ x: "max-content" }}
        />
      </div>
    </div>
  );
};

export default Posts;
