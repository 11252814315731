import React, { useState, useEffect } from "react";
import "./userList.css";
import { Breadcrumb, Button, Table, Input, Image, Select, Modal } from "antd";
import { callApi } from "../../api/apiCaller";
import routes from "../../api/routes";
import Loader from "../../components/loader/loader";
import moment from "moment/moment";

const { Option } = Select;

const UserList = () => {
  const [users, setUsers] = useState([]);
  const [isloading, setIsLoading] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [selectedRole, setSelectedRole] = useState("General User");
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [modalContent, setModalContent] = useState([]);

  const getAllUser = (role) => {
    let getRes = (res) => {
      setUsers(res?.data?.data);
    };

    const url = role
      ? `${routes.getAllUsers}?role=${role}`
      : routes.getAllUsers;

    callApi("GET", url, null, setIsLoading, getRes, (error) => {
      console.log("error", error);
    });
  };

  useEffect(() => {
    getAllUser(selectedRole);
  }, [selectedRole]);

  const handleRoleChange = (value) => {
    setSelectedRole(value || "General User");
  };

  const showModal = (content) => {
    setModalContent(content);
    setIsModalVisible(true);
  };

  const handleModalClose = () => {
    setIsModalVisible(false);
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      className: "role-name-column-header",
      align: "center",
      width: 250,
    },
    {
      title: "Email",
      dataIndex: "email",
      className: "type-name-column-header",
      align: "center",
      // width: 250,
    },
    {
      title: "Age",
      dataIndex: "age",
      className: "type-name-column-header",
      align: "center",
      width: 130,
    },
    {
      title: "Gender",
      dataIndex: "gender",
      className: "type-name-column-header",
      align: "center",
      width: 150,
    },
    {
      title: "Profile Picture",
      dataIndex: "profilePicture",
      align: "center",
      className: "action-column-header",
      width: 150,
    },
    {
      title: "Role",
      dataIndex: "role",
      align: "center",
      className: "action-column-header",
      width: 150,
    },
    // Conditionally render Subscription Type column for General User
    ...(selectedRole === "General User"
      ? [
          {
            title: "Subscription Type",
            dataIndex: "subscriptionType",
            align: "center",
            width: 150,
          },
        ]
      : []),
    // Conditionally render Languages column for Coach only
    ...(selectedRole === "coach"
      ? [
          {
            title: "Languages",
            dataIndex: "languages",
            align: "center",
            width: 180,
            render: (text, record) => (
              <Button onClick={() => showModal(record.languages)}>View</Button>
            ),
          },
        ]
      : []),
    {
      title: selectedRole === "General User" ? "Recommendation" : "Expertise",
      dataIndex:
        selectedRole === "General User" ? "recommendation" : "expertise",
      align: "center",
      width: 180,
      render: (text, record) => (
        <Button
          onClick={() =>
            showModal(
              record[
                selectedRole === "General User" ? "recommendation" : "expertise"
              ]
            )
          }
        >
          View
        </Button>
      ),
    },
    {
      title: "Created At",
      dataIndex: "created",
      align: "center",
      width: 250,
    },
    {
      title: "Block User",
      dataIndex: "blockORUnblock",
      align: "center",
      className: "action-column-header",
      width: 200,
      render: (text, record, index) => (
        <div className="server-roles-trash-btn">
          {record.isBlocked ? (
            <Button
              style={{
                width: "80px",
                color: "white",
                backgroundColor: "#52c41a",
              }}
              onClick={(event) => {
                blocked(index, event, record.email, false);
              }}
            >
              Unblock
            </Button>
          ) : (
            <Button
              style={{
                width: "80px",
                color: "white",
                backgroundColor: "#f5222d",
              }}
              onClick={(event) => {
                blocked(index, event, record.email, true);
              }}
            >
              Block
            </Button>
          )}
        </div>
      ),
    },
  ];

  const data = users
    ?.map((item) => {
      return {
        key: item._id,
        name: item?.name,
        created: moment(item?.createdAt)
          .local()
          .format("MMMM Do YYYY, h:mm:ss A"),
        role: item?.role,
        email: item?.email,
        gender: item?.gender,
        age: `${item?.age} years`,
        profilePicture: (
          <div className="product-list-image">
            <Image width={50} src={item?.image} alt="profile-image" />
          </div>
        ),
        subscriptionType: item?.subscriptionType,
        recommendation: item?.recommendation,
        expertise: item?.expertise,
        languages: item?.languages, // Add languages field for Coach role
        isBlocked: item?.isBlocked,
      };
    })
    .filter((item) => {
      const usernameMatch = item.name
        ?.toLowerCase()
        .includes(searchText?.toLowerCase());
      const emailMatch = item.email
        ?.toLowerCase()
        .includes(searchText?.toLowerCase());
      return usernameMatch || emailMatch;
    });

  const getRowClassName = (record, index) => {
    return index % 2 === 0 ? "server-role-even-row" : "server-role-odd-row";
  };

  let blocked = async (index, event, email, blockStatus) => {
    event.stopPropagation();
    let updatedUsers = [...users];
    const userIndex = updatedUsers.findIndex((user) => user.email === email);

    if (userIndex !== -1) {
      updatedUsers[userIndex].isBlocked = blockStatus;
    }

    let data = {
      email: email,
      isBlocked: blockStatus,
    };

    try {
      let res = await callApi("PATCH", routes.blockUser, data, setIsLoading);
      console.log("API response", res);
    } catch (error) {
      console.log("error", error);
    }

    setUsers([...updatedUsers]);
  };

  return (
    <div className="admin-products-main-container">
      <div className="configure-server-roles-main-heading-container">
        <h1>User List</h1>
        <div style={{ display: "flex", gap: "1rem" }}>
          <Select
            placeholder="Select Role"
            style={{ width: 200 }}
            value={selectedRole}
            onChange={handleRoleChange}
            allowClear
          >
            <Option value="General User">General User</Option>
            <Option value="coach">Coach</Option>
          </Select>
          <Input
            placeholder="Search Name or Email"
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
            style={{ width: "30rem" }}
          />
        </div>
      </div>
      <div className="server-roles-tb-main-container">
        <Table
          rowClassName={getRowClassName}
          columns={columns}
          dataSource={data}
          pagination={data?.length > 10}
          className="subscriptionapi-table"
          scroll={{ x: "max-content" }}
        ></Table>
        <Modal
          title={
            <span style={{ fontSize: "30px", fontWeight: "bold" }}>
              {selectedRole === "General User"
                ? "Recommendation"
                : selectedRole === "coach"
                ? "Languages"
                : "Expertise"}
            </span>
          }
          visible={isModalVisible}
          onCancel={handleModalClose}
          footer={[
            <Button
              key="close"
              onClick={handleModalClose}
              className="modal-close-button"
            >
              Close
            </Button>,
          ]}
        >
          <div className="modal-content-list">
            {modalContent && modalContent.length > 0 ? (
              <ul>
                {modalContent.map((item, index) => (
                  <li key={index}>{item}</li>
                ))}
              </ul>
            ) : (
              <p>No items to display</p>
            )}
          </div>
        </Modal>
      </div>
      <Loader loading={isloading} />
    </div>
  );
};

export default UserList;
