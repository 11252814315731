// export const BASE_URL = "http://localhost:4500/";
// export const BASE_URL = "http://ec2-52-87-209-182.compute-1.amazonaws.com/";
export const BASE_URL = "https://api.zouris.io/";

export default {
  // ----------------AUTH---------------//
  getAllUsers: BASE_URL + "user",
  signUp: BASE_URL + "user/signup",
  signIn: BASE_URL + "user/testLogin",
  blockUser: BASE_URL + "user/updateProfile",
  updateUser: BASE_URL + "/user",
  logOut: BASE_URL + "user/logout",

  getAudios: BASE_URL + "audio",
  deleteAudio: BASE_URL + "audio",
  createAudio: BASE_URL + "audio",
  updateAudio: BASE_URL + "audio",

  getChallenges: BASE_URL + "challenge",
  deleteChallenge: BASE_URL + "challenge",
  createChallenge: BASE_URL + "challenge",
  updateChallenge: BASE_URL + "challenge",

  getVideos: BASE_URL + "video",
  deleteVideo: BASE_URL + "video",
  createVideo: BASE_URL + "video",
  updateVideo: BASE_URL + "video",

  getPictures: BASE_URL + "picture",
  deletePicture: BASE_URL + "picture",
  createPicture: BASE_URL + "picture",
  updatePicture: BASE_URL + "picture",

  getPosts: BASE_URL + "post?filter=All",
  deletePost: BASE_URL + "post",
  createPost: BASE_URL + "post",
  updatePost: BASE_URL + "post",

  // -------------- Categories -------------- //
  getCategories: BASE_URL + "category?isAdmin=true",
  deleteCategory: BASE_URL + "category",
  createCategory: BASE_URL + "category",
  updateCategory: BASE_URL + "category",

  // -------------- Privacy Policy -------------- //
  getPrivacyPolicy: BASE_URL + "privacy",
  deletePrivacyPolicy: BASE_URL + "privacy",
  updatePrivacyPolicy: BASE_URL + "privacy",

  // -------------- FAQ's -------------- //
  createFAQs: BASE_URL + "faq",
  getFAQs: BASE_URL + "faq",
  deleteFAQs: BASE_URL + "faq",
  updateFAQs: BASE_URL + "faq",
  getAllBookings: BASE_URL + "allTransactions",
  getAllBookingsForAdmin: BASE_URL + "allTransactionsForAdmin",

  // -----------Products--------------//
  getProducts: BASE_URL + "/product/getAll?limit=1000",
  upDateProduct: BASE_URL + "/product/update",
  createProduct: BASE_URL + "/product/create",
  deleteProduct: BASE_URL + "/product/delete",
  allBookedProduct: BASE_URL + "/booking/allbookedproducts",

  // -------------Services-------------//
  getAllServices: BASE_URL + "/service/getall",
  createService: BASE_URL + "/service/create",
  deleteService: BASE_URL + "/service/delete",
  updateService: BASE_URL + "/service/update",
  allBookedServices: BASE_URL + "/booking/allbookedservices",
};
